<template>
  <div v-if="landing.advanced.global.underConstructor.show" style="height:540px;display:flex;flex-direction:column;justify-content:center;padding: 15px;">
      <div class="underConstructor_logo" v-if="landing.advanced.global.underConstructor.img" style="padding:15px;width:100%;text-align:center;">
          <img :src="landing.advanced.global.underConstructor.img" alt="" :style="`width:${landing.advanced.global.underConstructor.width}px;height:${landing.advanced.global.underConstructor.height}px;object-fit:cover;`">
      </div>
      <div class="underConstructor_text">
          <p  v-html="landing.advanced.global.underConstructor.text[landing.current_lang]"></p>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name:"UnderConstructorPreview",
    computed:{
        ...mapState(['landing'])
    }
}
</script>

<style lang="scss">

</style>